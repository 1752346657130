// libraries
import React from 'react';
import styled, { css } from 'styled-components';
// helpers
import { renderBlock } from 'helpers/renderBlock';
// constants
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'constants/settings';

export const BlocksContainer = ({ layouts, isFrontPage, linesGroup }) => {
    const withLineBlocks = layouts.filter((item) => item?.isLine);
    const withoutLineBlocks = layouts.filter((item) => !item?.isLine);
    const { mobileLine, tabletLine, desktopLine } = linesGroup;
    const lines = {
        mobile: mobileLine?.localFile.publicURL,
        tablet: tabletLine?.localFile.publicURL,
        desktop: desktopLine?.localFile.publicURL,
    };

    return (
        <>
            {withLineBlocks && (
                <StyledSectionWithLine isFrontPage={isFrontPage} lines={lines}>
                    {withLineBlocks.map((item, index) => (
                        <React.Fragment key={item.fieldGroupName + index}>
                            {renderBlock(item, isFrontPage)}
                        </React.Fragment>
                    ))}
                </StyledSectionWithLine>
            )}

            {withoutLineBlocks.map((item, index) => (
                <React.Fragment key={item.fieldGroupName + index}>{renderBlock(item, isFrontPage)}</React.Fragment>
            ))}
        </>
    );
};

const StyledSectionWithLine = styled.div`
    ${({ lines, isFrontPage }) => {
        if (isFrontPage) {
            return css`
                background: ${COLORS.success} no-repeat url(${lines.mobile}) 57% 25px;

                ${BREAKPOINT.md} {
                    background: ${COLORS.success} no-repeat url(${lines.tablet}) 50% 180px;
                }

                ${BREAKPOINT.lg} {
                    background: ${COLORS.success} no-repeat url(${lines.desktop}) 45% 195px;
                }
            `;
        }

        return css`
            background: no-repeat url(${lines.mobile}) 82% -50px / auto 100%;

            ${BREAKPOINT.md} {
                background: no-repeat url(${lines.tablet}) 69% 15px / auto 95%;
            }

            ${BREAKPOINT.lg} {
                background: no-repeat url(${lines.desktop}) 50% 5px / auto 100%;
            }
        `;
    }};
`;
