// libraries
import React from 'react';
import 'normalize.css';
import { graphql } from 'gatsby';
// components
import { SEO } from 'components/SEO';
import { GlobalGridVars } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Layout } from 'components/Layout';
import { BlocksContainer } from 'components/BlocksContainer';

const Page = ({ data }) => {
    const { layouts } = data.page.layouts;
    const { isFrontPage, lineSettings } = data.page;

    return (
        <Layout data={data.page}>
            <GlobalStyles />
            <GlobalGridVars />
            {layouts ? (
                <BlocksContainer layouts={layouts} isFrontPage={isFrontPage} linesGroup={lineSettings.linesGroup} />
            ) : null}
        </Layout>
    );
};
export default Page;

export const Head = ({ data }) => {
    return <SEO data={data.page.seo} />;
};

export const query = graphql`
    query ($id: String!) {
        page: wpPage(id: { eq: $id }) {
            isFrontPage
            databaseId
            title
            link
            seo {
                canonical
                metaDesc
                metaRobotsNofollow
                metaRobotsNoindex
                title
            }
            lineSettings {
                linesGroup {
                    mobileLine {
                        localFile {
                            publicURL
                        }
                    }
                    desktopLine {
                        localFile {
                            publicURL
                        }
                    }
                    tabletLine {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
            layouts {
                layouts {
                    ... on WpPage_Layouts_Layouts_Hero {
                        fieldGroupName
                        ...Hero
                    }
                    ... on WpPage_Layouts_Layouts_HeroRedesign {
                        fieldGroupName
                        ...HeroRedesign
                    }
                    ... on WpPage_Layouts_Layouts_Possibilities {
                        fieldGroupName
                        ...Possibilities
                    }
                    ... on WpPage_Layouts_Layouts_SendMoney {
                        fieldGroupName
                        ...SendMoney
                    }
                    ... on WpPage_Layouts_Layouts_Simple {
                        fieldGroupName
                        ...Simple
                    }
                    ... on WpPage_Layouts_Layouts_Talk {
                        fieldGroupName
                        ...Talk
                    }
                    ... on WpPage_Layouts_Layouts_Purchases {
                        fieldGroupName
                        ...Purchases
                    }
                    ... on WpPage_Layouts_Layouts_MoneyTransfer {
                        fieldGroupName
                        ...MoneyTransfer
                    }
                    ... on WpPage_Layouts_Layouts_SavingMoney {
                        fieldGroupName
                        ...SavingMoney
                    }
                    ... on WpPage_Layouts_Layouts_Destinations {
                        fieldGroupName
                        ...Destinations
                    }
                    ... on WpPage_Layouts_Layouts_CheapestWay {
                        fieldGroupName
                        ...CheapestWay
                    }
                    ... on WpPage_Layouts_Layouts_Recompensas {
                        fieldGroupName
                        ...Recompensas
                    }
                    ... on WpPage_Layouts_Layouts_Recompenses {
                        fieldGroupName
                        ...RecompensasMain
                    }
                    ... on WpPage_Layouts_Layouts_WellBeing {
                        fieldGroupName
                        ...WellBeing
                    }
                    ... on WpPage_Layouts_Layouts_Steps {
                        fieldGroupName
                        ...Steps
                    }
                    ... on WpPage_Layouts_Layouts_Pay {
                        fieldGroupName
                        ...Pay
                    }
                    ... on WpPage_Layouts_Layouts_Security {
                        fieldGroupName
                        ...Security
                    }
                    ... on WpPage_Layouts_Layouts_Faq {
                        fieldGroupName
                        ...Faq
                    }
                    ... on WpPage_Layouts_Layouts_Aboutus {
                        fieldGroupName
                        ...AboutUs
                    }
                    ... on WpPage_Layouts_Layouts_Rewards {
                        fieldGroupName
                        ...Rewards
                    }
                    ... on WpPage_Layouts_Layouts_Income {
                        fieldGroupName
                        ...Income
                    }
                    ... on WpPage_Layouts_Layouts_Company {
                        fieldGroupName
                        ...Company
                    }
                    ... on WpPage_Layouts_Layouts_WriteUs {
                        fieldGroupName
                        ...WriteUs
                    }
                    ... on WpPage_Layouts_Layouts_HeroAyuda {
                        fieldGroupName
                        ...HeroAyuda
                    }
                    ... on WpPage_Layouts_Layouts_AyudaBlock {
                        fieldGroupName
                        ...AyudaBlock
                    }
                    ... on WpPage_Layouts_Layouts_Advantages {
                        fieldGroupName
                        ...Advantages
                    }
                    ... on WpPage_Layouts_Layouts_CashOut {
                        fieldGroupName
                        ...CashOut
                    }
                    ... on WpPage_Layouts_Layouts_Payments {
                        fieldGroupName
                        ...Payments
                    }
                    ... on WpPage_Layouts_Layouts_HeroAdw {
                        fieldGroupName
                        ...HeroADW
                    }
                    ... on WpPage_Layouts_Layouts_Tarjeta {
                        fieldGroupName
                        ...Tarjeta
                    }
                    ... on WpPage_Layouts_Layouts_History {
                        fieldGroupName
                        ...History
                    }
                }
            }
        }
    }
`;
